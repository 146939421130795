import {
  Card,
  Container,
  CardHeader,
  CardContent,
  Stack,
  Typography,
  Box,
  Alert,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ToggleSettings from 'component/view/SiteDetails/Advanced/components/SiteSettings/ToggleSetting';
import { useMeChangeTwoAuth } from 'api/users';
import { useProfile } from 'api/profile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import LoadingContainer from 'component/partial/LoadingContainer';
import { useLogout } from 'utils/token';

export default function TwoFactorAuthentication() {
  const { t } = useTranslation();
  const { mutateAsync: userChangeTwoAuth } = useMeChangeTwoAuth();
  const { isLoading, data } = useProfile();
  const logout = useLogout();
  const twofaRequired = data?.data.result?.client?.twofactor_required === true;
  const twofaEnabled = data?.data.result?.client?.twofactor_enabled === true;

  if (isLoading) {
    return <LoadingContainer />;
  }

  return (
    <main id="mainContent">
      <Container maxWidth="sm">
        <Card>
          <CardHeader title={t('two_factor_authentication_title')} />
          <CardContent>
            <Stack spacing={2}>
              <Typography>{t('two_factor_authentication_description')}</Typography>
              {twofaRequired ? (
                <Alert severity="warning">{t('two_factor_required_and_enabled_warning')}</Alert>
              ) : null}
              <Stack direction="row" justifyContent="space-between" spacing={2} alignItems="center">
                <FontAwesomeIcon icon={faLock} />
                <Box>
                  <Typography fontWeight="bold">{t('two_factor_authentication')}</Typography>
                  <Typography>{t('two_factor_authentication_enabling_description')}</Typography>
                </Box>

                <ToggleSettings
                  onChange={async ({ value }) => {
                    await userChangeTwoAuth({ twofa: value === 1 });

                    if (value) {
                      logout();
                    }
                  }}
                  name="require_2fa"
                  disabled={twofaRequired === true && twofaEnabled === true}
                  value={twofaEnabled}
                />
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </Container>
    </main>
  );
}
